import React from "react";

const Form = () => {
  return (
    <div>
      <div className="form-sec margin_top">
        <div className="coustom_container">
          <div className="inner-form">
            <form action="">
              <div className="form_header">
                Form
              </div>

              <div className="fields">
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Username"
                  />
                </div>
                <div className="form-group">
                  <select className="input-fields" name="Country" id="Country">
                    <option value="India">India</option>
                    <option value="London">London</option>
                    <option value="Paris">Paris</option>
                    <option value="New York">New York</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="number"
                    placeholder="Phone number"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="date"
                    placeholder="Username"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="password"
                    placeholder="Enter password"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="button_form">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
