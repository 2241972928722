import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import OverView from "./OverView";

const Index = (props:any) => {
  return (
    <div>
      {/* <Sidebar /> */}
      <OverView pageTitle={props.pageTitle}/>
      {/* <Header /> */}
    </div>
  );
};

export default Index;
