import React from "react";

const SiteIncome = () => {
  return (
    <div>
      <div className="form-sec margin_top">
        <div className="coustom_container">
          <div className="inner-form">
            <form action="">
              <div className="form_header">
                Site Income Setting
              </div>

              <div className="fields">
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Min Withdraw Limit"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Max Withdraw Limit"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Charges"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="text"
                    placeholder="Roi Income %"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="button_form">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteIncome;
