import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url, contractAbi, contractAddress, sellContractAbi, sellContractAddress, supportedChainId } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { CheckCircleOutline, Deselect, FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import toasted from "../config/toast";
import { Tooltip } from "@mui/material";
// import tronWeb from "../config/tronWeb";

// import crypto from 'crypto-browserify';
// import TronWeb from 'tronweb';

const PendingTRC20Withdraw = (props) => {


  // const tronWeb = new TronWeb({
  //   fullNode: 'https://api.trongrid.io', // Replace with the appropriate full node URL
  //   solidityNode: 'https://api.trongrid.io', // Replace with the appropriate solidity node URL
  //   eventServer: 'https://api.trongrid.io', // Replace with the appropriate event server URL
  //   // privateKey: '', // Add your private key here if needed
  // });


  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalWithdraw: "", todayWithdrawAmount: "", totalApprovedWithdraw: "", totalPendingWithdraw: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [type, setType] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    getData();
  }, [searchedType, sort, searchedValue, itemOffset, limit]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = (selectAll) => {
    const allIds = tableRecords.map((val) => { return { 'id': val?._id, 'amount': val.payable_amount, 'wallet_address': val.wallet_address } });
    // console.log('allIds',allIds);
    setSelectedIds(selectAll ? allIds : []);

  };
  const handleCheckboxChange = (id, payable_amount, wallet_address, isChecked) => {

    if (selectAll) {
      return;
    }
    const obj = { 'id': id, 'amount': payable_amount, 'wallet_address': wallet_address };

    // if (selectedIds?.length == 0) {
    //   setSelectedIds([obj]);
    // } else {
    //   console.log('isChecked', isChecked);
    // if (isChecked) {

    if (selectedIds.filter(selectedId => selectedId.id == id)?.length == 0) {

      setSelectedIds([...selectedIds, obj]);

    } else {
      setSelectedIds(selectedIds.filter(selectedId => selectedId.id != id));
    }
    // }
    // }

  };



  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";
    }

    const Data = await _fetch(`${api_url}payout/pendingPayouts?sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});
    if (Data?.status === "success") {

      settableRecords(Data?.data?.data);
      setAllDetail(Data);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (itemOffset > 1) {
      setKeycount(0);
      var count = itemOffset - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  /////////////dappppppppp/////////////////////////////////


  ////////////////// dapp things //////////

  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [disconnectStataus, setdisconnectStataus] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);

  const [leftCoinBalance, setleftCoinBalance] = useState(0);

  const [message, setMessage] = useState('');

  // const connectTronLink = async () => {
  //   if (!tronWeb.ready) {
  //     console.log('TronLink is not installed.');
  //     return;
  //   }

  //   try {
  //     await tronWeb.setAddress(); // Request user's permission to connect to TronLink
  //     console.log('Connected to TronLink successfully!');
  //   } catch (error) {
  //     console.log('Error connecting to TronLink.');
  //     console.error(error);
  //   }
  // };

  const [webTron, setWebTron] = useState('');
  const [contract, setcontract] = useState('');


  const sendTRX = async (ids, amounts, addresses) => {

    try {

      await contract.withdrawMultiple(ids, amounts, addresses).send({
        from: account,
      }).then(async function (receipt) {
        // call node apiLOG

        console.log('receipt', receipt);
        // approvedSubmit(ids, receipt.transactionHash)

        // setGethash(receipt.transactionHash)

      })
      // })

    } catch (error) {
      console.log('Error sending trx.');
      console.error(error);
    }
  };


  var tronWeb1 = '';

  async function getTronWeb() {
    if (window.tronLink.ready) {
      tronWeb1 = window.tronLink.tronWeb;
      setWebTron(tronWeb1);
      connectContractAddress(tronWeb1);
    } else {
      const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
      if (res.code === 200) {
        tronWeb1 = window.tronLink.tronWeb;
        setWebTron(tronWeb1);
        connectContractAddress(tronWeb1)
      }
    }
    return tronWeb1;
  }

  const connectContractAddress = async (web) => {
    try {
      const contractCon = await web.contract().at('TMqb247ubycWh9VLz7Uuqrp5M9dSpGGyej'); // Replace with the actual USDT contract address
      setcontract(contractCon);
      // const getEstimatedGas = await tokenContract.methods.withdrawMultiple(ids, amounts, addresses).estimateGas({ from: account });
      const balance = await contractCon.checkBalance().call();
      const main_balance = await web.toDecimal(balance._hex) / 1000000;
      console.log('balance', main_balance);
    } catch (error) {
      console.log('Error sending USDT.');
      console.error(error);
    }
  };
  useEffect(() => {
    // registerUser()

  }, [account, disconnectStataus]);



  /////////////////dappp////////////////////////////////////
  const connectWallet = async () => {

  }




  const [amount, setAmount] = useState("");
  const [getSopnserWallet, setgetSopnserWallet] = useState('');
  const [gethash, setGethash] = useState('');

  const [approveLoader, setapproveLoader] = useState('0');
  const [changeSection, setchangeSection] = useState(false);
  const [activeLogin, setactiveLogin] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);


  const approveAndSendTokens = async (ids, amounts, addresses) => {
    // // if (amount >= 50) {
    // setbtnDisable(true)

    // // const web3 = props?.maskData?.web3;
    // // const account = props?.maskData?.address;
    // // const tokenContract = props?.maskData?.tokenContract;
    // // const StakeContract = props?.maskData?.StakeContract;
    // // console.log('web3', web3);
    // // console.log('account', account);
    // // console.log('tokenContract', tokenContract);
    // // console.log('StakeContract', StakeContract);

    // if (web3 && account && tokenContract) {
    //   setapproveLoader('1')

    //   // const approvalAmount = web3.utils.toWei(`${amount}`, 'ether'); // Replace with the desired approval amount
    //   try {
    //     // const _approvalGas = await tokenContract.methods.approve(StakeContractAddress, approvalAmount).estimateGas({
    //     //   from: account,
    //     // });


    //     // const approvalGas = await parseInt(_approvalGas.toString().replace("n", ""));
    //     const _gasPrice = await web3.eth.getGasPrice();
    //     const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));
    //     // console.log('gasPrice sadasd', gasPrice, approvalAmount);

    //     const gasPriceGwei = gasPrice;
    //     const gasPriceWei = Math.floor(gasPriceGwei * 1.3);

    //     // const approvalClear = await tokenContract.methods.approve(StakeContractAddress, approvalAmount).send({
    //     //   from: account,
    //     //   gasPrice: gasPriceWei.toString(),
    //     //   gasLimit: approvalGas
    //     // }).once('transactionHash', function (hash) {
    //     //   // console.log('approval hash: ', hash);

    //     // }).on('error', function (error) {
    //     //   // console.log('\n[ERROR]\n\n' + error
    //     //   //   .message);
    //     //   let msg = error.message.split(":");
    //     //   // toastr.error("Error " + error.code +
    //     //   //     " : " + msg[1]);
    //     // }).then(async function (receipt) {
    //     setapproveLoader('2')
    //     setchangeSection(false)
    //     console.log(ids, amounts, addresses);
    //     const getEstimatedGas = await tokenContract.methods.withdrawMultiple(ids, amounts, addresses).estimateGas({ from: account });
    //     console.log(getEstimatedGas);
    //     const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));

    //     // console.log('gasPrice', gasPrice, estimatedGas, approvalAmount);
    //     const _gasPriceGwei = gasPrice;
    //     const _gasPriceWei = Math.floor(_gasPriceGwei * 1.3);

    //     await tokenContract.methods.withdrawMultiple(ids, amounts, addresses).send({
    //       from: account,
    //       gasPrice: _gasPriceWei.toString(),
    //       gasLimit: estimatedGas
    //     }).once('transactionHash', function (hash) {
    //       console.log('transactionHash hash: ', hash);

    //       console.log(hash);
    //       // setGethash(hash)
    //       setchangeSection(true)
    //     }).on('error', function (error) {
    //       console.log('\n[ERROR]\n\n' + error
    //         .message);
    //       let msg = error.message.split(":");
    //       // toastr.error("Error " + error.code +
    //       //     " : " + msg[1]);
    //     }).then(async function (receipt) {
    //       // call node apiLOG

    //       console.log('receipt', receipt);
    //       approvedSubmit(ids, receipt.transactionHash)
    //       setactiveLogin(true)
    //       // setGethash(receipt.transactionHash)

    //     })
    //     // })

    //     console.log('Tokens approved and sent successfully');
    //   } catch (error) {
    //     console.error('Error approving and sending tokens:', error);
    //     toasted.error(error?.data?.message);
    //     setbtnDisable(false)
    //     setapproveLoader('3')


    //   }
    // }
    // // } else {
    // //   toasted.error('Please enter amount minimum $50');

    // // }
  };



  const SubmitData = async () => {
    if (text != "" && text != null) {
      var idArr = [];
      var amountArr = [];
      var walletArr = [];


      selectedIds?.map((val, key) => {
        idArr[key] = val.id;
        amountArr[key] = val.amount;
        walletArr[key] = val.wallet_address;
      })
      console.log('type', type);
      if (type == 'true') {

        sendTRX(['65b4e07bac9386bdee2ed7f6'], ['10000'], ['TWSam9bc83xStnSwTLq9G9LNnQFAyPmdmP']);
      } else {
        let data = {
          "_id": idArr,
          "withdraw_status": type,
          "remark": text,
          "hash": '',
        }
        // console.log('asdasdsa erroe');
        // let res = await _fetch(`${api_url}payout/payoutAccessAdmin`, "POST", data)
        // if (res?.status === 'success') {
        //   toasted.success(res?.message);
        // } else {
        //   toasted.error(res?.message);
        // }
      }
    } else {
      toasted.error('Please enter remark!');
    }
  }

  const approvedSubmit = async (ids, hash) => {
    let data = {
      "_id": ids,
      "withdraw_status": type,
      "remark": text,
      "hash": hash,
    }
    // console.log('idArr', idArr);
    let res = await _fetch(`${api_url}payout/payoutAccessAdmin`, "POST", data)
    if (res?.status === 'success') {
      toasted.success(res?.message);
    } else {
      toasted.error(res?.message);
    }
  }


  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">
          <div className="page_info">
            <div className="coustom_container">
              <div className="d-flex align-items-center justify-content-center">

                <h6> <PersonRounded /> Withdraw Request </h6>
                <div className="inner_links_header">

                  <FiberManualRecord className="dot" />
                  <a href="#">Home</a>
                </div>
              </div>
            </div>
          </div>

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Payout
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> ${Number(allDetail?.totalWithdraw)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Today Payout
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-success"> <span className="de-sc"></span> ${Number(allDetail?.todayWithdrawAmount)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Approved Payout
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-warning"> <span className="de-sc"></span> ${Number(allDetail?.totalApprovedWithdraw)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Pending Payout
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-danger"> <span className="de-sc"></span> ${Number(allDetail?.totalPendingWithdraw)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading heading__new">
              <h1>Pending Payout List</h1>
              {/* <div className="d-flex align-items-center gap-2"> </div> */}



              <div className="buttons-s__nds pe-2">


                <form action="">
                  <p className="mb-0">{leftCoinBalance.toFixed(2)}USDT</p>
                  <div className="form-group">
                    <select className="form-select" onChange={(e) => setSearchedType(e.target.value)}>
                      <option value={'username'}>Username</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="input-fields"
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearchedValue(e.target.value)} />
                  </div>
                </form>
                <div className="btn__sdcsds">


                  <button className="btn btn-success" onClick={() => toggleSelectAll(true)}>
                    <Tooltip title="Select All"> <CheckCircleOutline /> </Tooltip>
                  </button>

                  <button className="btn btn-2 btn-danger ms-2" onClick={() => toggleSelectAll(false)}>
                    <Tooltip title="Unselect All">  <Deselect /> </Tooltip>
                  </button>
                  <button onClick={getTronWeb}>Connect TronLink</button>

                  {/* <button type="button" className="Connect__wallet_button" onClick={connectTronLink}> {account ?' Connected Wallet' :' Connect Wallet'}</button> */}
                </div>

              </div>
            </div>

            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">#</th>
                      <th scope="col">Username</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Deduction</th>
                      <th scope="col">Payable Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Wallet Address</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val, key) => {

                        return (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                className="input__table_checkbox"
                                checked={selectedIds.filter(e => e.id == val?._id)?.length > 0 ? true : false}
                                onChange={(e) => handleCheckboxChange(val?._id, val?.payable_amount, val?.wallet_address, e.target.checked)}
                              />

                            </td>
                            <td>{itemOffset > 1 ? key + keyCount + 1 : key + 1}</td>
                            <td>{val?.username}</td>
                            <td>${val?.amount?.toFixed(2)}</td>
                            <td>${val?.deduction?.toFixed(2)}</td>
                            <td> ${val?.payable_amount?.toFixed(2)}</td>
                            <td>{val?.status}</td>
                            <td>{val?.remark}</td>
                            <td>{val?.wallet_address}</td>
                            <td>{dateformat(val?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}


              <div className="payout-off-on">
                <h6>Payout List Approve & Reject</h6>
                <div className="form-group">
                  <select onChange={(e) => setType(e.target.value)} className="form-select">
                    <option value="">Select Option</option>
                    <option value="true">Approve</option>
                    <option value="false">Reject</option>
                  </select>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} onChange={(e) => setText(e.target.value)} value={text}></textarea>
                  <div>
                    <button type="button" className="btn btn-success" onClick={SubmitData}>Submit</button>

                    {/* <button type="button" className="btn btn-success" onClick={() => { approvedSubmit(["65b40fdeefe0cb9ae21aeee9"], '0xc08f22e1a58f16d56aec90c64d66670f616fd1164a1fe0b4bef88b1dc80ae1a0') }}> test Submit</button> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingTRC20Withdraw;
