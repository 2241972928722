import React from "react";

const WorkingPopup = () => {
  return (
    <div>
      <div className="table-sec margin_top">
        <div className="coustom_container">
        <div className="table_main">
          <div className="heading">
            <h1 className="border-0">Current Working User Popup</h1>
            {/* <form action="">
              <div className="form-group">
                <input
                  className="input-fields"
                  type="search"
                  placeholder="Search"
                />
              </div>
              <button type="submit" className="search_button">
                Submit
              </button>
            </form> */}
          </div>
          <div className="outer-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr No.</th>
                  <th scope="col">Caption</th>
                  <th scope="col">Popup Image</th>
                  <th scope="col">Popup Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>lorem dummy</td>
                  <td>lorem</td>
                  <td>lorem</td>
                </tr>
              </tbody>
            </table>
            <div className="table_bottom">
              <div className="table_data_text">
                <p>Showing 0 to 0 of 0 entries</p>
              </div>

              <div className="pagination">
                <a href="#" className="previous">&laquo;Previous</a>
                <a href="#" className="active">
                  1
                </a>
                <a href="#">2</a>
                <a href="#">3</a>
                <a href="#" className="next">Next&raquo;</a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingPopup;
